import { createRouter, createWebHistory } from 'vue-router'
import orderInput from '../views/orderInput.vue'
import Error from '../views/error.vue'
import channels from '../views/channels.vue'

const routerHistory = createWebHistory()

const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: '/',
      component: orderInput,
      name:"orderinput"
    },
    {
      path: '/channels',
      component: channels,
      name: "channels",
      props: true
    },
    {
      path: '/channels/:id/:amount',
      component: channels,
      props: true
    },
    {
      // path: "*",
     path: "/:catchAll(.*)",
     name: "NotFound",
     component: Error,
   },
  ]
})

export default router
