<template>
  <div class="container mt-5">
    <h4 class="text-center">Payment Channels</h4>
    <div class="row row-cols-4">
      <div class="col">
        <div class="border p-5 border-2" @click="load">
          BPI
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
const axios = require('axios')


export default {
  name: 'channels',
  props:{
    amount:{default: null},
    id:{default:null}
  },
  mounted(){
    console.log('mounted');
    if(this.amount == null && this.id == null){
      this.$router.push({name: 'orderinput'})
    }
    this.$emit('mounted')
  },
  methods:{
    load(){
      axios.post('https://utils.polka.ph/payments/brankas-checkout.php', {
        order: this.id,
        amount: this.amount,
        bank: 'DUMMY_BANK_PERSONAL'
      })
      .then(function (response) {
        if(response.data.status == "success"){
          window.location.replace(response.data.response);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }
}
</script>
