<template>
  <div :style="'height:'+ spacer+'px;'">
    <nav class="navbar navbar-expand-md navbar-light bg-danger navbar-fixed " :style="'top:'+totalTopmargin+ 'px;'">
      <div class="container">
        <div class="navbar-brand">
          <router-link to="/">
            <img :src="logo" class="" :style="navphoto" @click="$emit('page','home')">
          </router-link>
        </div>
        <div v-if="open" class="w-100 cover" :style="'top:' + totalSpace +'px; height: calc(100vh - ' + totalSpace +'px);'" @click="close"></div>
        <div class="navbar-toggler hoverpointer border-0" @click="opennav">
          <span class="navbar-toggler-icon"></span>
        </div>

        <div v-show="open" :class="collapseClass" :style="'top: '+totalSpace+'px;'">
          <ul class="navbar-nav ms-auto mb-lg-0">
            <li class="nav-item ms-0 ms-md-4 pt-md-2 hoverpointer">
              <!--h5 class="m-2">Customer Care</h5-->
            </li>
            <li class="nav-item ms-0 ms-md-4 pt-md-2 hoverpointer">
              <div class="dropdown">
                <div type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <!--h5 class="m-2">Customer Care</h5-->
                </div>
                <ul class="dropdown-menu dropdown-menu-sm-end bg-pink border-0 pe-3 pe-sm-0" aria-labelledby="dropdownMenuButton1">
                  <li><div class="dropdown-item" @click="page('about')">Our Commitment</div></li>
                  <li><div class="dropdown-item" @click="page('help')">FAQ</div></li>
                  <li><div class="dropdown-item" @click="page('contact')">Contact Mirko</div></li>
                </ul>
              </div>
            </li>
            <slot>
            </slot>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>


<script>
export default {
  name: 'navBar',
  props:{
    docscroll: {type: Number, default: 0},
    topmargin: Number,
    spacer: Number,
    logo: String,
    logoh:String,
  },
  data(){
    return {
      open: false,
    }
  },
  watch:{
  },
  methods:{
    opennav(){
      this.open = !this.open
    },
    close(){
      this.open = false
    },
    page(page){
      this.close()
      this.$emit('page', page)
    }
  },
  computed:{
    collapseClass(){
      if(this.open){
        return "navbar-collapse navbar-collapse-vue bg-pink p-2"
      } else{
        return "navbar-collapse bg-pink"
      }
    },
    totalSpace(){
      if(this.docscroll < this.topmargin){
        return (parseInt(this.spacer) + parseInt(this.topmargin) )
      } else{
        return this.spacer
      }
    },
    totalTopmargin(){
      if(this.docscroll < this.topmargin){
        return this.topmargin
      } else{
        return 0
      }
    },
    navphoto(){
      return 'height: '+ this.logoh +'px; width: auto;'
    },
  },
}
</script>


<style>
  .navbar-collapse-vue{
    position: fixed;
    z-index: 5;
    left: 0;
    width: 100%
  }
  .navbar{
    position: fixed !important;
    z-index: 5;
    left: 0;
    right: 0;
  }
  .cover{
    position: fixed;
    left: 0;
    z-index: 4;
  }
  .cart-badge{
    position: relative;
    bottom:10px;
    right:20px;
  }
</style>
