<template>
  <div class="">
    not found
  </div>
</template>


<script>
// @ is an alias to /src

export default {
  name: '404',
}
</script>
