<template>
  <div style="min-height: 100vh">
    <navBar :logo="logo" logoh="40" topmargin="0" spacer="66"/>
    <router-view @confirmpay="gotochannels" @mounted="showmodal = false"/>
    <modal v-if="showmodal">
      <div class="text-center">
        <div class="spinner-border m-5" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import navBar from '@/main-components/navBar.vue'
import modal from '@/sub-components/modal.vue'
//import img from '@/assets/logo.png'

export default {
  name: 'app',
  components: {
    navBar, modal
  },
  data(){
    return {
      logo: "https://utils.polka.ph/imgs/logo-white.png",
      showmodal: false,
      order: null
    }
  },
  methods:{
    gotochannels(data){
      this.showmodal = true
      this.order = data
      this.$router.push({name: 'channels', params:{amount: data.amount, id: data.id}})
    }
  }
}
</script>


<style>

</style>
