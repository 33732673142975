<template>
  <div class="container" style="margin-top: 30vh">
    <div class="">
      <label class="form-label">Polka Order Number</label>
      <input class="form-control" v-model.trim="ordernum" @keyup.enter="check"/>
    </div>
    <modal v-if="showmodal" @bgclick="closemodal">
      <div v-if="!errormsg" class="p-3">
        <h2 class="text-center">Order Details</h2>
        <h5>Ship to:</h5>
        <p>{{shipto}}</p>
        <h5>Item:</h5>
        <p>{{item}}</p>
        <h5>Down Payment:</h5>
        <p>{{dp}}</p>
        <div class="text-center">
          <button class="btn btn-danger" @click="confirmpay">Confirm and Pay</button>
        </div>
      </div>
      <div v-else class="p-3">
        <h4>{{errormsg}}</h4>
      </div>
    </modal>
  </div>
</template>


<script>
import modal from "@/sub-components/modal.vue"

const axios = require('axios')


export default {
  name: "",
  props: {
  },
  components:{ modal
  },
  data(){
    return{
      ordernum: "",
      showmodal: false,
      item: "",
      dp: "",
      shipto: "",
      orderid: null,
      errormsg: false,

    }
  },
  methods:{
    check(){
      let comp = this
      axios.get('https://utils.polka.ph/payments/get-order.php?ordernum='+ this.ordernum)
      .then(function (response) {
        if(response.data.status == 'success'){
          if(response.data.response.length > 0 ){
            comp.item = response.data.response[0].item
            comp.dp = response.data.response[0].down_payment
            comp.shipto = response.data.response[0].shipping_name
            comp.orderid = response.data.response[0].id
            comp.showmodal = true
          } else {
            comp.errormsg = "Order Number not found"
            comp.showmodal = true
          }
        } else{
          comp.errormsg = "Order Number not found"
          comp.showmodal = true
        }
      })
      .catch(function (error) {
        comp.errormsg = "Encountered Internal Error Message. Please try again or contact suppor"
        console.log(error);
        comp.showmodal = true
      });
    },
    closemodal(){
      this.errormsg = false;
      this.showmodal = false
    },
    confirmpay(){
      this.$emit('confirmpay',{item: this.item, amount: this.dp, shipto: this.shipto, ordernum: this.ordernum, id: this.orderid})
      this.showmodal = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
